<template>
  <div class="dealOrder">
    <div class="dealOrderTitle">
      <div class="myDealOrder">
        <p>产品预售订单</p>
      </div>
    </div>
    <div class="searchList">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-date-picker
            class="width-100"
            v-model="searchForm.startTime"
            type="datetime"
            placeholder="下单开始时间"
          ></el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-date-picker
            class="width-100"
            v-model="searchForm.endTime"
            type="datetime"
            placeholder="下单结束时间"
          ></el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-select v-model="searchForm.status" placeholder="请选择状态">
            <el-option
              v-for="item in statusOptions"
              :key="item.code"
              :label="item.value"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-button type="danger" @click="getDatas()">搜索</el-button>
          <el-button @click="resetSearch()">重置</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="tableData" border>
      <el-table-column prop="code" label="协议号"></el-table-column>
      <el-table-column prop="name" label="商品"></el-table-column>
      <el-table-column prop="quantity" label="数量"></el-table-column>
      <el-table-column prop="price" label="价格"></el-table-column>
      <el-table-column prop="enterpriseName" label="公司"></el-table-column>
      <el-table-column prop="enterprisePhone" label="联系方式"></el-table-column>
      <el-table-column prop="status" label="状态"></el-table-column>
      <el-table-column prop="createTime" label="下单时间" width="180">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime | DateFormate }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="pagination">
      <el-col :span="24">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      tableData: [
        // {
        //   code: "clw009999",
        //   name: "钢材",
        //   quantity: 2,
        //   price: "300.00 RMB",
        //   enterpriseName: "公司名称",
        //   enterprisePhone: "13200000003",
        //   status: "已付首款",
        //   createTime: "2020-03-06"
        // },
        // {
        //   code: "clw009999",
        //   name: "钢材",
        //   quantity: 2,
        //   price: "300.00 RMB",
        //   enterpriseName: "公司名称",
        //   enterprisePhone: "13200000003",
        //   status: "已付尾款",
        //   createTime: "2020-03-06"
        // }
      ],
      searchForm: {
        startTime: null,
        endTime: null,
        status: null
      },
      statusOptions: [
        // {
        //   value: "选项1",
        //   label: "未成交"
        // },
        // {
        //   value: "选项2",
        //   label: "已成交"
        // }
      ],
      current: 1,
      pageSize: 10,
      totalPage: null
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    this.getDatas();
    this.getSellOrderStatus();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    resetSearch() {
      this.searchForm = {
        startTime: null,
        endTime: null,
        status: null
      }
      this.getDatas();
    },
    getSellOrderStatus() {
      http.postFront(protocolFwd.param_selectSellOrderStatus).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.statusOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getDatas() {
      protocolFwd.param_queryLtOrderTwo.param.firmId = this.sessionInfoGetter.firmId;
      protocolFwd.param_queryLtOrderTwo.param.startTime = this.searchForm.startTime ? this.searchForm.startTime : null;
      protocolFwd.param_queryLtOrderTwo.param.endTime = this.searchForm.endTime ? this.searchForm.endTime : null;
      protocolFwd.param_queryLtOrderTwo.param.status = this.searchForm.status ? this.searchForm.status : null;
      protocolFwd.param_queryLtOrderTwo.param.page = this.current - 1;
      protocolFwd.param_queryLtOrderTwo.param.size = this.pageSize;
      http.postFront(protocolFwd.param_queryLtOrderTwo).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.tableData = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    }
  }
};
</script>
<style scoped>
.dealOrder {
  padding: 0 15px;
}
.dealOrderTitle {
  overflow: hidden;
}
.dealOrder .searchList {
  margin-bottom: 10px;
}
.dealOrder .myDealOrder {
  float: left;
  line-height: 40px;
}
.dealOrderTitle .myDealOrder p {
  font-size: 16px;
}
.dealOrder .pagination {
  margin-top: 15px;
}
</style>
